<template>
  <div class="topbar">
    <WarehouseSelect width="300px" v-if="showWarehouseDropdown" />
    <!--begin: Language bar -->
    <div class="topbar-item">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        right
        no-flip
        v-if="!!languageFlag"
        ref="lang-dropdown"
      >
        <template v-slot:button-content>
          <span class="symbol symbol-2 ma-0 pa-0">
            <!-- <img :src="item.flag || 'image'" alt="" /> -->

            <span
              class="symbol symbol-35"
              :class="[
                languageFlag.abbreviation === 'ar'
                  ? 'symbol-light-warning'
                  : 'symbol-light-danger',
              ]"
            >
              <span class="symbol-label font-size-h5 font-weight-bold">
                {{ languageFlag.abbreviation.toUpperCase() }}
              </span>
            </span>
          </span>
        </template>
        <b-dropdown-text tag="div" class="min-w-md-175px">
          <KTDropdownLanguage
            v-on:language-changed="onLanguageChanged"
          ></KTDropdownLanguage>
        </b-dropdown-text>
      </b-dropdown>
    </div>
    <!--end: Language bar -->
    <!--begin: User Bar -->
    <KTQuickUser v-if="getCurrentUser"></KTQuickUser>
    <!--end: User Bar -->
  </div>
</template>

<script>
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import WarehouseSelect from "@/view/layout/extras/offcanvas/WarehouseSelect.vue";
import { ROUTES } from "@/core/constants/warehouseSpecificRoutes";
import i18nService from "@/core/services/i18n.service.js";
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";

export default {
  name: "KTTopbar",
  data() {
    return {
      showWarehouseDropdown: false,
      warehouseRoutes: ROUTES,
      languageFlag: null,
      languages: i18nService.languageslist,
    };
  },
  components: {
    KTQuickUser,
    WarehouseSelect,
    KTDropdownLanguage,
  },
  mounted() {
    this.onLanguageChanged();
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(
        (val) => val.abbreviation === i18nService.getActiveLanguage()
      );
      this.$refs["lang-dropdown"].hide();
    },
    checkRouteChange(route) {
      if (this.warehouseRoutes.includes(route)) {
        this.showWarehouseDropdown = true;
        return;
      }
      this.showWarehouseDropdown = false;
    },
  },
  computed: {
    getCurrentUser() {
      return this.$store.getters.currentUser.data;
    },
    // getLanguageData() {
    //   return this.languages.find(
    //     (val) => val.abbreviation === i18nService.getActiveLanguage()
    //   );
    // },
  },
  watch: {
    $route(to) {
      this.checkRouteChange(to.path);
    },
  },
  beforeMount() {
    this.checkRouteChange(this.$route.path);
  },
};
</script>
